import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { InventoryClient } from "../../../../clients/InventoryClient"
import { LocationContext } from "../../../../contexts/Location"
import {
  getSeriesMpgDisclaimerCode,
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
  toUsd,
} from "../../../../helpers"
import { Image } from "../../../atoms/Image"
import { LocalInventoryLinks } from "../../../molecules/LocalInventoryLinks"
import { OfferCard, OfferCardProps } from "../../../molecules/OfferCard"
import { FilterSidebar } from "../FilterSidebar"
import { SeriesInfoProps } from "./Main.d."
import { generateInventoryLink } from "../../../../helpers"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { Link } from "../../../atoms/Link"
import Icon from "../../../atoms/Icon"
import {
  electricSeriesNames,
  useElectricPluginSeriesNames,
} from "../../../../hooks/useElectricPluginSeriesNames"
import { useTealiumContext } from "../../../../contexts/Tealium"

const Main = ({
  seriesData,
  seriesImage,
  dispatch,
  state,
  modelNames,
  uniqueOfferTypes,
  seriesYears,
  filteredOffers,
  offers,
}: SeriesInfoProps): JSX.Element => {
  const [{ city, dealers }] = useContext(LocationContext)
  const { language, _ } = useContext(LanguageContext)
  const [{ disclaimers }, disclaimersDispatch] = useContext(DisclaimersContext)
  const { vehicleTealData } = useTealiumContext()
  seriesYears?.sort()
  const seriesYear =
    Array.isArray(seriesYears) &&
    seriesYears[seriesYears?.length - 1]?.toString()
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(
    seriesData?.name,
    seriesYear
  )
  const pluginSeriesNames = useElectricPluginSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    seriesData?.name?.toLocaleLowerCase()
  )
  const electricName = electricSeriesNames()
  const isAllElectric = electricName.includes(
    seriesData?.name?.toLocaleLowerCase().toLocaleLowerCase()
  )

  let seriesPath = normalizeSeriesNameForLink(seriesData?.name)
  if (seriesData?.name?.toLowerCase()?.includes("hybrid")) {
    seriesPath += "?hybrid=true"
  }
  const getCount = async () => {
    const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
    try {
      setInventoryCount("...")
      const { count } = await InventoryClient.getCount("", {
        dealer: dealerIds,
        series: seriesData?.name,
      })
      setInventoryCount(count)
    } catch (error) {
      setInventoryCount(error)
    }
  }

  const [inventoryCount, setInventoryCount] = useState<
    string | number | (() => Promise<void>)
  >(() => getCount)

  useEffect(() => {
    getCount()
  }, [dealers])

  return (
    <section
      css={[
        tw`max-w-7xl w-full grid grid-cols-1 auto-rows-min gap-y-6`,
        tw`lg:(grid-cols-4)`,
        "background: radial-gradient(farthest-side, #dee2e6 0%, #fff 90%);",
      ]}
    >
      {/* Series name and series page link */}
      {seriesData?.name && (
        <div
          css={[
            tw`row-start-1 row-end-2 col-start-1 col-end-2 justify-self-start px-4`,
          ]}
        >
          <p
            css={[
              tw`text-3xl font-semibold`,
              seriesData?.name == "bZ4X" ? tw`normal-case` : tw`uppercase`,
            ]}
          >
            {seriesData?.name}
          </p>
          <Link
            animated
            animatedThin
            to={`/${seriesPath}`}
            css={tw`mb-2 text-xs lg:mb-0`}
          >
            {_("View Models")}{" "}
            <Icon.Chevron
              color="red-400"
              css={[tw`w-2 ml-0.5 mb-0.5 inline-block`]}
            />
          </Link>
        </div>
      )}
      {/* Series MSRP and MPG */}
      <div
        css={[
          tw`row-start-3 row-end-4 col-start-1 col-end-2 flex gap-7 justify-self-center text-2xl px-4`,
          tw`lg:(row-start-2 row-end-3 justify-self-start )`,
        ]}
      >
        {seriesData?.msrp && (
          <div css={[tw`flex flex-col items-center`]}>
            <div>
              <span>$</span>
              {toUsd(seriesData?.msrp)}
              {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                disclaimersDispatch(toggleDisclaimersModal(code))
              )}
            </div>
            <div css={["font-size: 0.625rem; line-height: 1.3rem"]}>
              {_("Starting MSRP")}
            </div>
          </div>
        )}
        <div css={[tw`flex flex-col items-center`]}>
          <div>
            {isAllElectric ? (
              <span>{seriesData?.range} mi</span>
            ) : (
              <>
                {seriesData?.mpgCity && <span>{seriesData?.mpgCity}</span>}
                {seriesData?.mpgHighway && (
                  <>
                    /<span>{seriesData?.mpgHighway}</span>
                  </>
                )}
              </>
            )}

            {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
              disclaimersDispatch(toggleDisclaimersModal(code))
            )}
          </div>
          <div css={["font-size: 0.625rem; line-height: 1.3rem"]}>
            {!isElectricPluginSeries && !isAllElectric && _("MPG")}
            {isElectricPluginSeries && _("MPG/MPGe")}
            {isAllElectric && _("Range")}
          </div>
        </div>
      </div>
      <div
        css={[
          tw`row-start-4 row-end-5 col-start-1 col-end-2 flex flex-wrap justify-center items-center gap-1 px-4`,
          tw`lg:(row-start-3 row-end-4 justify-start items-start)`,
        ]}
      >
        <LocalInventoryLinks
          city={city}
          dealerCount={dealers?.length}
          inventoryCount={inventoryCount}
          series={seriesData?.name}
          inventoryLink={generateInventoryLink({
            series: seriesData?.slug.toLowerCase(),
          })}
          extraTealiumValues={{
            vehicle_segment_vehicle_page:
              vehicleTealData?.vehicle_segment_vehicle_page,
          }}
        />
      </div>
      {seriesImage && (
        <div
          css={[
            tw`row-start-2 row-end-3 col-start-1 col-end-2 flex justify-center`,
            tw`lg:(row-start-1 row-end-4 col-start-2 col-end-5)`,
          ]}
        >
          <Image imageData={seriesImage} css={[tw`object-contain`]} />
        </div>
      )}

      <FilterSidebar
        dispatch={dispatch}
        state={state}
        modelNames={modelNames}
        uniqueOfferTypes={uniqueOfferTypes}
        seriesYears={seriesYears}
        seriesName={seriesData?.name}
        css={[
          tw`row-start-4 row-end-5 col-start-1 col-end-2 pl-4 pr-8 pt-10 hidden`,
          tw`lg:( block )`,
        ]}
      />
      <section
        css={[
          tw`row-start-5 row-end-6 col-start-1 col-end-2 items-start flex gap-2 py-6 px-5 overflow-x-scroll scrollbar-hide w-full`,
          tw`lg:(col-start-2 col-end-5 row-start-4 row-end-5 flex-wrap gap-8 px-4 overflow-x-hidden)`,
        ]}
      >
        {filteredOffers?.length === 0
          ? offers?.map((node: OfferCardProps, i: number) => (
              <OfferCard
                css={[tw`min-w-[288px] shadow-3`]}
                {...node}
                cardType={node?.cardLabel}
                cardLabel={
                  language === "es" ? node?.cardLabelES : node?.cardLabel
                }
                analyticsId={`offer details:offers:${i + 1}`}
              />
            ))
          : filteredOffers?.map((node: OfferCardProps, i: number) => (
              <OfferCard
                css={[tw`min-w-[288px] shadow-3`]}
                {...node}
                cardType={node?.cardLabel}
                cardLabel={
                  language === "es" ? node?.cardLabelES : node?.cardLabel
                }
                analyticsId={`offer details:offers:${i + 1}`}
              />
            ))}
        {!offers && (
          <div>
            {_(
              "No offers to display for this series, please check back later."
            )}
          </div>
        )}
      </section>
    </section>
  )
}

export default Main
