import React, { useEffect, useReducer, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import tw from "twin.macro"
import { Offer } from "../global"
import {
  Color,
  FilterAction,
  FilterState,
  SeriesOffersModel,
} from "../components/organisms/OffersSeriesTemplate/SeriesOffers.d"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { TopBar } from "../components/organisms/OffersSeriesTemplate/TopBar"
import Main from "../components/organisms/OffersSeriesTemplate/Main/Main"
import { reduceModelGroups, reformatCategory } from "../helpers"

import { SEO } from "../components/atoms/SEO"
import { HeroCarousel } from "../components/organisms/OffersPage/HeroCarousel"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useOffersData from "../hooks/useOffersData"
import { useTealiumContext } from "../contexts/Tealium"

/**
 * Series Offers template displays all offers for each series with filter options for model, offer type, and year.
 * Divided into 4 components, ModelSelector is the top row of models selectable for filtering, TopBar is the area
 * just beneath ModelSelector with the offers count and (on mobile) filter menu button. Main is the main body of
 * the component, and FilterSidebar is the sidebar with all filter selections.
 *
 * @author Tyler
 * @param {Object} data Data returned from Sanity: page + offers
 *
 * @todo Fix page types
 */

const SeriesOffersTemplate: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const { hero } = page

  // Destructure hero data
  const { carouselItems, _type: heroType } = hero

  const seriesData = page?.vehicles[0]?.series
  const additionalSeries = page?.vehicles?.flatMap((vehicle: any) =>
    vehicle?.additionalSeries?.map((series: any) => series?.name)
  )[0]
  const initialState: FilterState = {
    filtered: [],
  }

  const [offers, setOffers] = useState([])

  // Pull in offers context and filter it by series name
  const pullOffers = useOffersData()?.map((offer: Offer) => {
    if (offer?.type === "TFS Finance Cash") {
      offer.type = "APR"
    } else if (offer?.type === "TFS Lease Cash") {
      offer.type = "Lease"
    }
    return offer
  })
  useEffect(() => {
    setOffers(
      pullOffers?.filter(
        (node: Offer) =>
          node?.series?.name === seriesData?.name ||
          node?.series?.name === additionalSeries
      )
    )
  }, [])

  // Filter state
  const reducer = (state: FilterState, action: FilterAction) => {
    if (action?.clearAll) return { filtered: [] }
    switch (action?.isChecked) {
      case true:
        return {
          filtered: [
            ...state?.filtered?.filter((node: string) => node !== action?.type),
          ],
        }
      case false:
        return {
          filtered: [...state?.filtered, action?.type],
        }
    }
  }

  // Filter state
  const [state, dispatch] = useReducer(reducer, initialState)

  // Filter mobile menu SlideOver state
  const [slideOverActive, setSlideOverActive] = useState(false)

  // Sort out models from model groups
  const models: any = reduceModelGroups(page?.vehicles[0].models)

  // Pull only names of models
  const modelNames: Object[] = models?.map(
    (node: SeriesOffersModel) => node?.model?.name
  )

  // Find the model toggled as featured model
  let featuredModel: SeriesOffersModel = models?.find(
    (node: any) => node?.isFeatured
  )
  //if no featured model, set to first model
  featuredModel = featuredModel || models[0]

  // Main image displayed above offer cards (default to first color of featured model)
  const seriesImage: IGatsbyImageData =
    featuredModel?.model?.colors?.find((node: Color) =>
      node?.exterior?.colors?.find(
        (another: any) => another?._key === featuredModel?.featuredColor
      )
    )?.exteriorImages[2] ?? featuredModel?.model?.colors[0]?.exteriorImages[2]

  // Pull types of offers that exist in the returned data, remove duplicates
  const uniqueOfferTypes: Object[] = [
    ...new Set(offers?.map((offer: Offer) => offer?.type)),
  ]

  // Ascertain which series years the offers are for, store unique values and pull top 2
  const seriesYears: Object[] = [
    ...new Set(offers?.map((node: Offer) => node?.series?.currentYear)),
  ].slice(0, 2)

  // Filter logic, filter based on provided parameters and remove duplicates
  const filteredOffers: Object[] = [
    ...new Set([
      ...offers?.filter(
        (node: Offer) =>
          node?.includedModels?.some((model: { name: string }) =>
            state?.filtered?.includes(model?.name)
          ) ||
          state?.filtered?.includes(node?.type) ||
          state?.filtered?.includes(node?.series?.currentYear)
      ),
    ]),
  ]

  // Pull length of filteredOffers object, displayed above series information
  const offersCount = filteredOffers?.length

  //Tealium
  const { handleTealView } = useTealiumView()
  const { setVehicleTealData } = useTealiumContext()

  useEffect(() => {
    const category = reformatCategory(seriesData?.category)
    handleTealView({
      page_name: `${category}_${seriesData.name.toLowerCase()}`,
      tealium_event: "offers_series",
      page_type: "offers",
      vehicle_segment_vehicle_page: category,
      vehicle_model: seriesData?.name,
      vehicle_model_truncated: seriesData?.name,
    })
    setVehicleTealData({
      vehicle_segment_vehicle_page: category,
      vehicle_model: seriesData?.name,
      vehicle_model_truncated: seriesData?.name,
    })
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <section css={[tw`w-full relative`]}>
        <HeroCarousel
          slides={carouselItems}
          offers={offers}
          extraTealiumValues={{
            vehicle_segment_vehicle_page: reformatCategory(
              seriesData?.category
            ),
          }}
        />
      </section>
      {/* <article css={[tw`bg-gradient-to-b from-gray-100 pt-10`]}> */}
      {/* Filter options */}
      {/* <ModelSelector
          dispatch={dispatch}
          state={state}
          models={models}
          series={seriesData?.name}
        /> */}
      {/* </article> */}
      <article css={[tw`flex flex-col items-center pb-14`]}>
        <TopBar
          dispatch={dispatch}
          state={state}
          offers={offers}
          offersCount={offersCount}
          slideOverActive={slideOverActive}
          setSlideOverActive={setSlideOverActive}
          modelNames={modelNames}
          uniqueOfferTypes={uniqueOfferTypes}
          seriesYears={seriesYears}
        />
        <Main
          dispatch={dispatch}
          state={state}
          offers={offers}
          seriesData={seriesData}
          seriesImage={seriesImage}
          modelNames={modelNames}
          uniqueOfferTypes={uniqueOfferTypes}
          seriesYears={seriesYears}
          filteredOffers={filteredOffers}
        />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query SeriesOffersTemplateQuery($id: String) {
    page: sanityPageSeriesOffers(id: { eq: $id }) {
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      ...SeriesOffersData
      hero {
        _type
        carouselItems {
          _key
          model {
            costs {
              baseMSRP
            }
          }
          desktopImage {
            ...SanityImageData
          }

          desktopVehicle {
            ...SanityImageData
          }
          mobileImage {
            ...SanityImageData
          }
          mobileVehicle {
            ...SanityImageData
          }
          offerType
          vehicleRef {
            year
            series {
              name
              msrp
              slug
            }
            models {
              ... on SanityVehicleModel {
                _key
                featuredModel
                featuredColor {
                  colors {
                    _key
                  }
                }
                model {
                  ... on SanityModel {
                    id
                    name
                    series {
                      msrp
                      mpgCity
                      mpgHighway
                      currentYear
                      name
                      slug
                      range
                    }
                  }
                  ... on SanityCustomModel {
                    id
                    name
                    series {
                      msrp
                      mpgCity
                      mpgHighway
                      currentYear
                      name
                      slug
                      range
                    }
                  }
                }
              }
              ... on SanityVehicleModelGroup {
                _key
                _type
                models {
                  _key
                  featuredModel
                  featuredColor {
                    colors {
                      _key
                    }
                  }
                  model {
                    ... on SanityModel {
                      id
                      name
                      series {
                        msrp
                        mpgCity
                        mpgHighway
                        currentYear
                        name
                        slug
                        range
                      }
                    }
                    ... on SanityCustomModel {
                      id
                      name
                      series {
                        msrp
                        mpgCity
                        mpgHighway
                        currentYear
                        name
                        slug
                        range
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SeriesOffersTemplate
