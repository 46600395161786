import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { Button } from "../../../atoms/Button"
import Icon from "../../../atoms/Icon"
import { SlideOver } from "../../../atoms/SlideOver"
import { SeriesOffersModel } from "../SeriesOffers.d"
import { TopBarProps } from "./TopBar.d"

const TopBar = ({
  dispatch,
  state,
  offers,
  offersCount,
  slideOverActive,
  setSlideOverActive,
  modelNames,
  uniqueOfferTypes,
  seriesYears,
}: TopBarProps): JSX.Element => {
  const { _, language } = useContext(LanguageContext)
  return (
    <section css={[tw`max-w-7xl w-full px-4`]}>
      <div
        css={[
          tw`flex justify-between items-center w-full pt-11 pb-4 mb-12 border-b border-gray-300`,
          tw`lg:(pt-6)`,
        ]}
      >
        <div>
          <span css={[tw`font-semibold`]}>
            {offersCount === 0 ? offers?.length : offersCount}
          </span>{" "}
          {language === "en" && " Toyota "} {_("Offer")}
          {offersCount !== 1 && "s"}
          {language === "es" && " Toyota "}
        </div>
        <div>
          <SlideOver active={slideOverActive} setActive={setSlideOverActive}>
            <article
              css={[tw`row-start-4 row-end-5 col-start-1 col-end-2 pr-8 pt-10`]}
            >
              <section
                css={[
                  tw`flex justify-between text-xs border-b border-gray-300 pb-2 mb-2`,
                ]}
              >
                <div>{_("Filter")}</div>
                <button
                  onClick={() => dispatch({ clearAll: true })}
                  css={[
                    tw`p-0 text-xs font-book text-red-400 normal-case cursor-pointer`,
                  ]}
                >
                  {_("Clear All")}
                </button>
              </section>
              {modelNames && (
                <section css={[tw`flex flex-col gap-2 mb-7`]}>
                  <h3 css={[tw`font-semibold`]}>{_("Models")}</h3>
                  {modelNames?.map((node: string) => {
                    return (
                      <div>
                        <input
                          key={node}
                          type="checkbox"
                          checked={state?.filtered?.includes(node)}
                          onChange={() => {
                            dispatch({
                              type: node,
                              isChecked: state?.filtered?.includes(node),
                            })
                          }}
                        />
                        <span css={[tw`ml-2 font-semibold`]}>{node}</span>
                      </div>
                    )
                  })}
                </section>
              )}

              {uniqueOfferTypes && (
                <section css={[tw`flex flex-col gap-2 mb-7`]}>
                  <h3 css={[tw`font-semibold`]}>{_("Offer Types")}</h3>
                  {uniqueOfferTypes?.map((node: string) => {
                    return (
                      <div>
                        <input
                          key={node}
                          type="checkbox"
                          checked={state?.filtered?.includes(node)}
                          onChange={() => {
                            dispatch({
                              type: node,
                              isChecked: state?.filtered?.includes(node),
                            })
                          }}
                        />
                        <span css={[tw`ml-2 font-semibold`]}>{node}</span>
                      </div>
                    )
                  })}
                </section>
              )}

              {seriesYears && (
                <section css={[tw`flex flex-col gap-2 mb-7`]}>
                  <h3 css={[tw`font-semibold`]}>{_("Year")}</h3>
                  {seriesYears?.map((node: string, index: number) => {
                    return (
                      <div>
                        <input
                          key={node}
                          type="checkbox"
                          checked={state?.filtered?.includes(node)}
                          onChange={() => {
                            dispatch({
                              type: node,
                              isChecked: state?.filtered?.includes(node),
                            })
                          }}
                        />
                        <span css={[tw`ml-2 font-semibold`]}>
                          {seriesYears?.length === 2 && index === 0
                            ? _("Current")
                            : _("Previous")}{" "}
                          ({node})
                        </span>
                      </div>
                    )
                  })}
                </section>
              )}
            </article>
          </SlideOver>
          <Button
            onClick={() => setSlideOverActive(!slideOverActive)}
            css={[
              tw`flex items-center z-0 normal-case font-book gap-3 py-2 px-6 overflow-visible`,
              tw`lg:(hidden)`,
            ]}
          >
            {_("Filter")} <Icon.Filter color="red-400" css={[tw`h-4`]} />
          </Button>
        </div>
      </div>
    </section>
  )
}

export default TopBar
